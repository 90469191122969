import React, { useState, useEffect } from "react"
import { getDefaultAddress, getAddresses } from "../../services/addresses"
import { getUser } from "../../services/auth"
import Container from "../container/container"
import AddressItem from "../addresses/address-item"
import AddAddressForm from "../addresses/add-address-form"
import style from "./delivery-details.mod.scss"
import Button from "../button/button"

const DeliveryDetails = ({ setAddress }) => {
  const [defaultAddress, setDefaultAddress] = useState(false)
  const [addresses, setAddresses] = useState(false)
  const [succeeded, setSucceeded] = useState(false)
  const [error, setError] = useState(null)

  const [showDefaultAddressSection, setShowDefaultAddressSection] =
    useState(true)
  const [showChangeAddressSection, setShowChangeAddressSection] =
    useState(false)
  const [showAddAddressSection, setShowAddAddressSection] = useState(false)

  useEffect(() => {
    const user = getUser()
    const getDefaultAddressData = {
      user: user,
    }
    const defaultAddress = getDefaultAddress(getDefaultAddressData).then(
      (res) => {
        if (res) {
          setDefaultAddress(res)
          setAddress(res)
        } else {
          setDefaultAddress(false)
          setAddress(false)
        }
      }
    )

    const addressesData = {
      user: user,
    }
    const addresses = getAddresses(addressesData).then((res) => {
      if (res.data.totalAddressCount > 0) {
        setAddresses(res.data.address)
      } else {
        setAddresses([])
        //setError("You have no saved addresses")
      }
    })

    // console.log("showChangeAddressSection ", user)
  }, [showChangeAddressSection, showAddAddressSection, error, succeeded])

  const handleAddressFormSubmission = (data) => {
    // console.log("handleAddressFormSubmission", data)
    clearSections()
    defaultSectionState()
  }

  const handleShowChangeAddressSection = (boolean) => {
    clearSections()
    setShowChangeAddressSection(boolean)
  }

  const handleShowAddAddressSection = (boolean) => {
    clearSections()
    setShowAddAddressSection(boolean)
  }

  //clear all the sections to false
  const clearSections = () => {
    setShowDefaultAddressSection(false)
    setShowChangeAddressSection(false)
    setShowAddAddressSection(false)
  }

  //if no section is true, show default address section
  const defaultSectionState = () => {
    setShowDefaultAddressSection(false)
    if (
      showChangeAddressSection == false &&
      showAddAddressSection == false &&
      showAddAddressSection == false
    ) {
      setShowDefaultAddressSection(true)
    }
  }

  useEffect(() => {
    defaultSectionState()
  }, [showChangeAddressSection, showAddAddressSection, showAddAddressSection])

  return (
    <Container>
      {showDefaultAddressSection && (
        <DefaultAddressSection
          defaultAddress={defaultAddress}
          handleShowChangeAddressSection={handleShowChangeAddressSection}
          handleShowAddAddressSection={handleShowAddAddressSection}
        />
      )}
      {showChangeAddressSection && (
        <ChangeAddressSection
          handleShowChangeAddressSection={handleShowChangeAddressSection}
          addresses={addresses}
          handleShowAddAddressSection={handleShowAddAddressSection}
          setError={setError}
          setSucceeded={setSucceeded}
        />
      )}
      {showAddAddressSection && (
        <AddAddressFormSecton
          handleShowChangeAddressSection={handleShowChangeAddressSection}
          handleAddressFormSubmission={handleAddressFormSubmission}
          defaultAddress={defaultAddress}
        />
      )}
    </Container>
  )
}

export default DeliveryDetails

const DefaultAddressSection = ({
  defaultAddress,
  handleShowChangeAddressSection,
  handleShowAddAddressSection,
}) => {
  return (
    <div>
      {defaultAddress ? (
        <DefaultAddressYes
          defaultAddress={defaultAddress}
          handleShowChangeAddressSection={handleShowChangeAddressSection}
        />
      ) : (
        <DefaultAddressNo
          handleShowAddAddressSection={handleShowAddAddressSection}
        />
      )}
    </div>
  )
}

const DefaultAddressYes = ({
  defaultAddress,
  handleShowChangeAddressSection,
}) => {
  return (
    <div>
      <AddressItem
        item={defaultAddress}
        allowChange
        changeOnclick={() => {
          handleShowChangeAddressSection(true)
        }}
        heading={"Delivery address"}
      />
    </div>
  )
}

const DefaultAddressNo = ({ handleShowAddAddressSection }) => {
  return (
    <Button
      onClick={() => {
        handleShowAddAddressSection(true)
      }}
    >
      {"Add Address"}
    </Button>
  )
}

const ChangeAddressSection = ({
  handleShowChangeAddressSection,
  addresses,
  handleShowAddAddressSection,
  setError,
  setSucceeded,
}) => {
  return (
    <div className={style.delivery__wrap}>
      <div className={style.delivery__list}>
        {addresses.map((item) => (
          <AddressItem
            setError={setError}
            setSucceeded={setSucceeded}
            item={item}
          />
        ))}
      </div>
      <div className={style.delivery__controls}>
        <Button
          primary
          fullWidth
          onClick={() => {
            handleShowAddAddressSection(true)
          }}
        >
          {"Add New Address"}
        </Button>
        <Button
          fullWidth
          onClick={() => {
            handleShowChangeAddressSection(false)
          }}
        >
          {"Done"}
        </Button>
      </div>
    </div>
  )
}

const AddAddressFormSecton = ({
  handleShowChangeAddressSection,
  handleAddressFormSubmission,
  defaultAddress,
}) => {
  const handleCancel = () => {
    handleShowChangeAddressSection(true)
  }
  return (
    <div className={style.delivery__form}>
      <h5>Add delivery address</h5>
      <AddAddressForm
        secondary
        cancelMethod={defaultAddress ? handleCancel : null}
        handleAddressFormSubmission={handleAddressFormSubmission}
      />
    </div>
  )
}
