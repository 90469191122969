import React, { useState, useEffect } from "react"
import { useForm, FormProvider } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"
import Container from "../container/container"
import Input from "../form/Input"
import { checkUserVerified } from "../../services/forgot-password"
import { getUser, isGuestRole } from "../../services/auth"
import { Link } from "gatsby"

const schema = yup
  .object({
    email: yup
      .string()
      .email("Email must be a valid email")
      .required("Email is required"),
    first_name: yup.string().required("First Name is required"),
    last_name: yup.string().required("Last Name is required"),
    username: yup.string(),
  })
  .required()

const PersonalDetails = ({ setDetails }) => {
  const [generalMsg, setGeneralMsg] = useState("")

  const [defaultFirstName, setDefaultFirstName] = useState("")
  const [defaultLastName, setDefaultLastName] = useState("")
  const [defaultEmail, setDefaultEmail] = useState("")
  const [helpPreviousGuestMsg, setHelpPreviousGuestMsg] = useState(false)

  const methods = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    reValidateMode: "onChange",
    defaultValues: {
      first_name: "",
      last_name: "",
      email: "",
    },
  })
  const {
    register,
    handleSubmit,
    getValues,
    setError,
    setValue,
    reset,
    trigger,
    defaultValues,
    reValidateMode,
    formState: { errors, isValid, isDirty },
  } = methods

  const handleDetails = (formData) => {
    if (formData) {
      formData.username = getUserName()
      setDetails(formData)
    } else {
      setDetails(false)
    }
  }

  const getDefaultDetails = () => {
    const user = getUser()

    if (!isGuestRole() || user.card_brand) {
      setValue("first_name", user.first_name)
      setValue("last_name", user.last_name)
      setValue("email", user.email)

      setDefaultFirstName(user.first_name)
      setDefaultLastName(user.last_name)
      setDefaultEmail(user.email)

      if (user.email && user.last_name && user.first_name) {
        handleDetails({
          first_name: user.first_name,
          last_name: user.last_name,
          email: user.email,
        })
      }
    }
  }

  const getUserName = () => {
    const user = getUser()
    let username = user.username
    // console.log("username: ", username)
    if (username == null) {
      username =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15)
    }
    return username
  }

  useEffect(() => {
    getDefaultDetails()
  }, [])

  useEffect(() => {
    if (isDirty) {
      setGeneralMsg("")
      
      if (isValid) {
        const formData = getValues()

        formData.username = getUserName()

        const checkUserVerifiedData = {
          email: formData.email,
        }

        if (isGuestRole()) {
          checkUserVerified(checkUserVerifiedData).then((res) => {
            if (res.message.includes("Email is not registered")) {
              handleDetails(formData)
              setHelpPreviousGuestMsg(false)
            } else if (res.message.includes("account is already verified")) {
              setGeneralMsg("")
              setHelpPreviousGuestMsg(true)
              handleDetails(false)
            } else {
              setHelpPreviousGuestMsg(false)
              handleDetails(false)
              setGeneralMsg(
                "You already have an account with us. We've sent you an email to reset your password."
              )
              //send forgotten password email to guest user!!
            }
            // console.log("response:", res)
          })
        } else {
          handleDetails(formData)
        }

        reset(
          {},
          {
            keepErrors: true,
            keepDirty: false,
            keepTouched: false,
            keepIsValid: false,
            keepSubmitCount: false,
          }
        )
      } else {
        handleDetails(false)
      }
    }
  }, [methods.formState])

  return (
    <Container>
      <FormProvider {...methods}>
        <form noValidate>
          <Input
            label={"First Name"}
            name="first_name"
            {...register("first_name")}
            required
            placeholder={"Your First Name"}
            errorMessage={errors.first_name?.message}
            readOnly={defaultFirstName ? true : false}
            secondary
          />
          <Input
            label={"Last Name"}
            name="last_name"
            {...register("last_name")}
            required
            placeholder={"Your Last Name"}
            errorMessage={errors.last_name?.message}
            readOnly={defaultLastName ? true : false}
            secondary
          />
          <Input
            label={"Email"}
            name="email"
            {...register("email")}
            required
            placeholder={"Your Email"}
            errorMessage={errors.email?.message}
            readOnly={defaultEmail ? true : false}
            secondary
          />
        </form>
      </FormProvider>
      {!!generalMsg && (
        <p className="input--error">
          <small>{generalMsg}</small>
        </p>
      )}
      {helpPreviousGuestMsg && (
        <div>
        <p className="input--error">
          <small>
          It looks like you've used our site before either as a guest, or you have a registered account. Please log in to continue. If you don't think you have a password, please click 'forgot password' and a reset link will be send to you.
          </small>
        
        <p>
          <Link to="/login"><small>Login</small></Link> <small>|</small> <Link to="/forgot-password-request"><small>Forgot Password</small></Link>
        </p>
        </p>
        </div>
      )}
    </Container>
  )
}

export default PersonalDetails
