import React, { useState, useEffect } from "react"
import {
  getPaymentMethods,
  getDefaultPaymentMethod,
} from "../../services/payment-methods"
import { getUser } from "../../services/auth"
import Container from "../container/container"
import AddCardElement from "../payment-methods/add-card-element"
import PaymentMethodItem from "../payment-methods/payment-methods-item"
import style from "./payment-method.mod.scss"
import Button from "../button/button"

const PaymentMethod = ({ setPaymentMethod }) => {
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState(false)
  const [paymentMethods, setPaymentMethods] = useState([])
  const [succeeded, setSucceeded] = useState(false)
  const [error, setError] = useState(null)

  const [showDefaultSection, setShowDefaultSection] = useState(true)
  const [showChangeSection, setShowChangeSection] = useState(false)
  const [showAddSection, setShowAddSection] = useState(false)

  useEffect(() => {
    const user = getUser()

    const getDefaultPaymentMethodData = {
      user: user,
    }
    getDefaultPaymentMethod(getDefaultPaymentMethodData).then((res) => {
      if (res) {
        setDefaultPaymentMethod(res)
        setPaymentMethod(res)
      } else {
        setDefaultPaymentMethod(false)
        setPaymentMethod(false)
      }
    })

    const getPaymentMethodsData = {
      user: user,
    }
    getPaymentMethods(getPaymentMethodsData).then((res) => {
      // console.log("getPaymentMethods use() Response: ", res)

      if (res.data.cards) {
        setPaymentMethods(res.data.cards)
      } else {
        setPaymentMethods([])
      }
    })
  }, [showChangeSection, showAddSection, error, succeeded])

  const handlePaymentMethodSubmission = (data) => {
    // console.log("handlePaymentMethodSubmission", data)
    clearSections()
    defaultSectionState()
  }

  const handleShowChangeSection = (boolean) => {
    clearSections()
    setShowChangeSection(boolean)
  }

  const handleShowAddSection = (boolean) => {
    clearSections()
    setShowAddSection(boolean)
  }

  //clear all the sections to false
  const clearSections = () => {
    setShowDefaultSection(false)
    setShowChangeSection(false)
    setShowAddSection(false)
  }

  //if no section is true, show default address section
  const defaultSectionState = () => {
    setShowDefaultSection(false)
    if (
      showChangeSection == false &&
      showAddSection == false &&
      showAddSection == false
    ) {
      setShowDefaultSection(true)
    }
  }

  useEffect(() => {
    defaultSectionState()
  }, [showChangeSection, showAddSection, showAddSection])

  return (
    <Container>
      {showDefaultSection && (
        <DefaultSection
          defaultPaymentMethod={defaultPaymentMethod}
          handleShowChangeSection={handleShowChangeSection}
          handleShowAddSection={handleShowAddSection}
        />
      )}
      {showChangeSection && (
        <ChangeSection
          handleShowChangeSection={handleShowChangeSection}
          paymentMethods={paymentMethods}
          handleShowAddSection={handleShowAddSection}
          setError={setError}
          setSucceeded={setSucceeded}
        />
      )}
      {showAddSection && (
        <AddPaymentMethodFormSection
          handleShowChangeSection={handleShowChangeSection}
          handlePaymentMethodSubmission={handlePaymentMethodSubmission}
          defaultPaymentMethod={defaultPaymentMethod}
        />
      )}
    </Container>
  )
}

export default PaymentMethod

const DefaultSection = ({
  defaultPaymentMethod,
  handleShowChangeSection,
  handleShowAddSection,
}) => {
  return (
    <div>
      {defaultPaymentMethod ? (
        <DefaultPaymentMethodYes
          defaultPaymentMethod={defaultPaymentMethod}
          handleShowChangeSection={handleShowChangeSection}
        />
      ) : (
        <DefaultPaymentMethodNo handleShowAddSection={handleShowAddSection} />
      )}
    </div>
  )
}

const DefaultPaymentMethodYes = ({
  defaultPaymentMethod,
  handleShowChangeSection,
}) => {
  return (
    <div
      onClick={() => {
        handleShowChangeSection(true)
      }}
    >
      <PaymentMethodItem
        {...defaultPaymentMethod}
        heading={"Payment method"}
        allowChange={true}
        changeOnclick={handleShowChangeSection}
        noControls
      />
    </div>
  )
}

const DefaultPaymentMethodNo = ({ handleShowAddSection }) => {
  return (
    <Button
      onClick={() => {
        handleShowAddSection(true)
      }}
    >
      {"Add Payment Method"}
    </Button>
  )
}

const ChangeSection = ({
  handleShowChangeSection,
  paymentMethods,
  handleShowAddSection,
  setError,
  setSucceeded,
}) => {
  return (
    <div>
      <div className={style.payment__wrap}>
        <div className={style.payment__list}>
          {paymentMethods.map((item) => (
            <PaymentMethodItem
              setError={setError}
              setSucceeded={setSucceeded}
              {...item}
            />
          ))}
        </div>
        <div className={style.payment__controls}>
          <Button
            primary
            fullWidth
            onClick={() => {
              handleShowAddSection(true)
            }}
          >
            {"Add New Payment Method"}
          </Button>
          <Button
            fullWidth
            onClick={() => {
              handleShowChangeSection(false)
            }}
          >
            {"Done"}
          </Button>
        </div>
      </div>
    </div>
  )
}

const AddPaymentMethodFormSection = ({
  handleShowChangeSection,
  handlePaymentMethodSubmission,
  defaultPaymentMethod,
}) => {
  const handleCancel = () => {
    handleShowChangeSection(true)
  }
  return (
    <div className={style.payment__form}>
      <AddCardElement
        cancelMethod={defaultPaymentMethod ? handleCancel : null}
        handlePaymentMethodSubmission={handlePaymentMethodSubmission}
      />
    </div>
  )
}
