export const eventProductClick = (data) => {
  // console.log("eventProductClick", data)
  window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "productClick",
    ecommerce: {
      click: {
        products: [
          {
            name: data.name, // Name or ID is required.
            id: data.id,
            price: data.price.toFixed(2),
            brand: data.winery.name,
            category: data.category,
          },
        ],
      },
    },
  })
}

export const setFilterEvent = (data) => {
  console.log("setFilterEvent", data)
  window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "remarketing",
    remarketing: {
      product_id: "0",
      product_name: "0",
      wine_grapes: data.grapes || "0",
      wine_pairings: data.pairings || "0",
      wine_region: data.regions || "0",
      wine_type: data.types || "0",
      wine_wineries: "0",
    },
  })
}

export const setWineEvent = (data) => {
  console.log("setFilterEvent", data)
  window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "remarketing",
    remarketing: {
      product_id: data.id || "0",
      product_name: data.name || "0",
      wine_grapes: data.grape || "0",
      wine_pairings: data.pairings || "0",
      wine_region: data.region || "0",
      wine_type: data.type || "0",
      wine_wineries: data.winery || "0",
    },
  })
}

export const eventProductDetailImpression = (data) => {
  // console.log("eventProductDetailImpression", data)
  window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "view_item",
    ecommerce: {
      detail: {
        products: [
          {
            name: data.name,
            id: data.id,
            price: data.price.toFixed(2),
            brand: data.winery.name,
            category: data.category,
            ihw_grape: data.grape[0].name,
            ihw_winery: data.winery,
            ihw_body_type: data.body_type,
            ihw_maker_description: data.maker_description,
            ihw_taste_characteristics: data.taste_characteristics,
            ihw_year: data.year,
            ihw_unit: data.unit,
            ihw_food_pairing: data.food_pairing,
            ihw_wine_descriptors: data.wine_descriptors,
            ihw_wine_tags: data.wine_tags,
            ihw_acidity: data.acidity,
          },
        ],
      },
    },
  })
}

export const eventAddToCart = (data, cartData) => {
  // console.log("eventAddToCart", data, cartData)
  window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "addToCart",
    ecommerce: {
      currencyCode: "AUD",
      add: {
        products: [
          {
            name: data.name,
            id: data.id,
            price: data.price.toFixed(2),
            brand: data.brand,
            category: data.category,
            quantity: cartData.quantity,
          },
        ],
      },
    },
  })
}

export const eventRemoveFromCart = (data) => {
  // console.log("eventRemoveFromCart", data)
  window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "removeFromCart",
    ecommerce: {
      remove: {
        products: [
          {
            name: data.name,
            id: data.id,
            price: data.price.toFixed(2),
            quantity: data.quantity,
            brand: data.brand,
          },
        ],
      },
    },
  })
}

export const eventCheckout = (checkout) => {
  // console.log('checkout', checkout)
  const items = checkout.data.item
  const products = items.map((item) => {
    return {
      name: item.name,
      id: item.wine_id,
      price: item.price.toFixed(2),
      brand: item.winery_name,
      quantity: item.selected_quantity,
      category: item.category_name,
    }
  })

  window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "checkout",
    ecommerce: {
      checkout: {
        products: products,
      },
    },
  })
}

export const eventPurchase = (checkout) => {
  // console.log('purchase',checkout)
  const items = checkout.data.item
  const products = items.map((item) => {
    return {
      name: item.name,
      id: item.wine_id,
      price: item.price.toFixed(2),
      brand: item.winery_name,
      quantity: item.selected_quantity,
      category: item.category_name,
    }
  })

  const transactionId = Math.floor(Math.random() * 1000000000)

  window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "purchase",
    ecommerce: {
      purchase: {
        actionField: {
          id: transactionId, // Transaction ID. Required for purchases and refunds.
          affiliation: "IHW Website Checkout",
          revenue: checkout.data.order_total.toFixed(2), // Total transaction value (incl. tax and shipping)
          tax: checkout.data.tax.toFixed(2),
          shipping: checkout.data.shipping.toFixed(2),
        },
        products: products,
      },
    },
  })
}

export const eventSearch = (value) => {
  window.dataLayer.push({ ecommerce: null })
  window.dataLayer.push({
    event: "searchRequest",
    searchRequestInput: value,
  })
}

//write a function that will push the data to the dataLayer for "view_cart" using window.Datalayer.push

export const eventViewCart = (data) => {
  if (data.length === 0) {
    return
  }

  //data is an array of objects, each object contains "item" which is also an array of objects. Loop the data to store all item objects in a new array called "products"

  // console.log("eventViewCart", data)

  const products = []
  let total = 0
  let minQtyMet = false
  data.forEach((item) => {
    //get "order_total" and add to total
    total += item.order_total
    if (item.winery_bottle_count >= item.minimum_wines_per_order) {
      minQtyMet = true
    }
    item.item.forEach((product) => {
      products.push(product)
    })
  })

  // console.log("eventViewCart minQtyMet", minQtyMet)

  const items = products.map((item) => {
    return {
      item_name: item.name,
      item_id: item.wine_id,
      price: item.price.toFixed(2),
      item_brand: item.winery_name,
      quantity: item.selected_quantity,
    }
  })

  // console.log("eventViewCart", items)

  window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "view_cart",
    ecommerce: {
      currency: "AUD",
      value: total.toFixed(2),
      items: items,
    },
  })

  window.dataLayer.push({ ecommerce: null }) // Clear the previous ecommerce object.
  window.dataLayer.push({
    event: "min_order_qty_met",
    value: minQtyMet,
  })
}
