export const getCheckout = async (data) => {
  //TODO - Check auth first. Can't get checkout if not logged in.

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "version-code": "1",
      "device-type": "iOS",
      authorization: `Bearer ${data.user.api_token}`,
    },
    body: JSON.stringify(data),
    redirect: "follow",
  }
  // console.log("boomfa", requestOptions)
  try {
    const response = await fetch(
      `${process.env.GATSBY_PORTAL_URL}/get-checkout-data`,
      requestOptions
    ).then((res) => {
      return res.json()
    })

    // console.log("POST: api/cart/get-checkout-data", response)
    return response
  } catch (error) {
    return error
  }
}

export const processCheckout = async (data) => {
  //TODO - Check auth first. Can't get checkout if not logged in.

  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      "version-code": "1",
      "device-type": "iOS",
      authorization: `Bearer ${data.user.api_token}`,
    },
    body: JSON.stringify(data),
    redirect: "follow",
  }
  // console.log("boomfa", requestOptions)
  try {
    const response = await fetch(
      `${process.env.GATSBY_PORTAL_URL}/checkout`,
      requestOptions
    ).then((res) => {
      return res.json()
    })

    // console.log("POST: api/checkout/checkout", response)
    return response
  } catch (error) {
    return error
  }
}
