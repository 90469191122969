import React, { useEffect, useState } from "react"
import style from "./cart-checkout.mod.scss"
import demoImage from "../../assets/images/demoProduct.png"

const CartCheckout = ({ cart }) => {
  const { item, totalBottels, shipping, order_total, free_shipping } = cart

  const [numBottle, setNumBottle] = useState(0)
  const [shippingCost, setShippingCost] = useState(shipping)

  useEffect(() => {
    let num = 0
    item.forEach((wine) => {
      num += wine.selected_quantity
    })
    setNumBottle(num)

    if (free_shipping) {
      setShippingCost("FREE")
    } else {
      setShippingCost(`$${shipping.toFixed(2)}`)
    }
  }, [cart])

  const subtotal = order_total - shipping

  return (
    <div className={style.cart}>
      <h4>{`${item.length} items (${numBottle} bottles)`}</h4>
      <div>
        {item.map((wine) => (
          <LineItem wine={wine} />
        ))}
      </div>
      <div className={style.cart__totals}>
        <div className={style.cart__totals_sub}>
          <p>{"Subtotal"}</p>
          <p>${subtotal.toFixed(2)}</p>
        </div>
        <div className={style.cart__totals_shipping}>
          <p>{"Shipping"}</p>
          <p>{shippingCost}</p>
        </div>
        <div className={style.cart__totals_total}>
          <h5>{"Total (AUD)"}</h5>
          <p>${order_total.toFixed(2)}</p>
        </div>
      </div>
    </div>
  )
}

export default CartCheckout

const LineItem = ({ wine }) => {
  const handleImgError = (e) => {
    e.target.src = demoImage
  }

  const total = wine.price * wine.selected_quantity
  return (
    <div className={style.cart__item}>
      <div className={style.cart__item_image}>
        <img src={wine.image} alt="" onError={handleImgError} />
      </div>
      <div className={style.cart__item_detail}>
        <div className={style.cart__item_text}>
          <p>{wine.name}</p>
          <span>{`QTY: ${wine.selected_quantity}`}</span>
        </div>
        <div className={style.cart__item_price}>
          <p>${total.toFixed(2)}</p>
        </div>
      </div>
    </div>
  )
}
