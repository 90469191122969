export const mailchimpOptIn = async (origin, email, guest, details) => {

  var myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");

  const data = {
    "origin": origin,
    "email": email,
    "guest": guest,
    "user_info": details,
  }

  const response = await fetch(process.env.GATSBY_MAKE_OPTIN_WEB_HOOK, {
    headers: myHeaders,
    method: "POST",
    body: JSON.stringify(data),
  }).then((res) => {
    return
  })
}
