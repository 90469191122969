import React, { useState } from "react"
import cn from "classnames"
import style from "./input-checkbox.mod.scss"

const InputCheckbox = ({ checked, onChange, animate, name, text, errorMessage }) => {
  return (
    <label className={cn(style.checkbox, {[style.checkbox__checked]: checked, [style.checkbox__animate]: animate})}>
      <input name={name} type="checkbox" checked={checked} onChange={onChange} />
      <span>{text}</span>
      {errorMessage && <span className={style.error}>{errorMessage}</span>}
      <span className={style.box}>
        <svg width="48" height="48" viewBox="0 0 48 48">
          <path d="M12.5 25.5L20.7 33.7 35.2 14.4" stroke="currentColor" strokeWidth="2" fill="none" fillRule="evenodd"/>
        </svg>
      </span>
    </label>
  )
}

export default InputCheckbox
